<!--
 * @Author: yangliao
 * @Date: 2020-11-03 08:22:32
 * @LastEditTime: 2020-12-22 08:58:20
 * @LastEditors: Please set LastEditors
 * @Description: 用水报装客户须知
 * @FilePath: /netHallOfficialAccounts/src/views/Business/Expand/index.vue
-->
<template>
  <div class="notice">
    <div class="notice-head">
      <van-field center name="radio" label="选择客户类型" input-align="right">
        <template #input>
          <van-radio-group v-model="businessType" direction="horizontal">
            <van-radio
              class="notice-head-radio"
              v-for="item in businessTerms.child"
              :key="item.code"
              :name="item.code"
              @click="businessEvent(item)"
            >
              {{ item.name }}
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field v-if="businessSubclassData" name="radio">
        <template #input>
          <van-radio-group v-model="businessSubclass" direction="horizontal">
            <van-radio
              class="notice-head-radio"
              v-for="item in businessSubclassData"
              :key="item.code"
              :name="item.code"
              @click="selectBusinessSubclass(item)"
            >
              {{ item.name }}
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
    </div>
    <div class="notice-content">
      <div v-html="noticeHtml" v-if="showNotice"></div>
    </div>
    <div class="notice-foot">
      <div class="notice-foot-left">
        <van-checkbox v-model="checked" shape="square" icon-size="20px" v-if="showNotice">已阅读</van-checkbox>
      </div>
      <div class="notice-foot-right">
        <van-button class="nfr-button" :disabled="!checked && showNotice" type="info" @click="verification">
          下一步
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import { Toast } from 'vant';
import { termsReckon } from '@/utils/businessRoute';
import { getCustReadingByCode, verification } from '@/api/business';
export default {
  data() {
    return {
      businessSubclassData: null,
      businessType: '',
      businessSubclass: '',
      moduleCode: '',
      moduleName: '',
      checked: false,
      custReadingData: null,
      noticeHtml: null,
      showNotice: false
    };
  },
  computed: {
    ...mapState('user', {
      homeInfo: (state) => state.homeInfo,
      businessNews: (state) => state.businessNews
    }),
    businessTerms() {
      return termsReckon('0001');
    }
  },
  mounted() {
    this.into();
  },
  methods: {
    async into() {
      this.businessEvent(this.businessTerms.child[0]);
    },
    businessEvent(data) {
      console.log(data);
      this.checked = false;
      this.businessType = data.code;
      if (data.child !== undefined && data.child.length > 0) {
        this.businessSubclassData = data.child;
        this.businessSubclass = '';
        this.moduleCode = '';
      } else {
        this.businessSubclassData = null;
        this.businessSubclass = '';
        this.moduleCode = data.code;
        this.moduleName = data.name;
        this.getReadingByCode();
      }
    },
    selectBusinessSubclass(data) {
      this.checked = false;
      this.businessSubclass = data.code;
      this.moduleCode = data.code;
      this.moduleName = data.name;
      this.getReadingByCode();
    },
    /* 获取用户须知 */
    async getReadingByCode() {
      let data = { businessCode: this.businessTerms.code, moduleCode: this.moduleCode };
      let res = await getCustReadingByCode(data);
      let { status, resultData } = res;
      if (status === 'complete' && resultData) {
        this.custReadingData = resultData;
        this.noticeHtml = resultData.content;
        this.showNotice = resultData.isUse;
      } else {
        this.custReadingData = null;
        this.noticeHtml = null;
        this.showNotice = false;
      }
    },
    async nextStep() {
      if (this.businessType) {
        let data = {
          businessState: 'add',
          businessCode: this.businessTerms.code,
          moduleCode: this.moduleCode,
          busHandlingId: null,
          custReadingId: this.custReadingData.custReadingId,
          moduleName: this.businessTerms.name + ' - ' + this.moduleName
        };
        await store.dispatch('user/setBusinessNews', data);
        this.$router.push({ path: '/Expand/InstallWater' });
      } else {
        Toast.fail('请选择客户类型');
      }
    },
    // 校验后进入下一步
    async verification() {
      let arg = {
        moduleCode: this.moduleCode
      };
      const { status, resultData } = await verification(arg);
      if (status === 'complete') {
        this.nextStep();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.notice {
  display: flex;
  flex-direction: column;
  .notice-head-radio {
    margin: 6px 20px 6px 0;
  }
  .notice-content {
    background: #ffffff;
    margin: 16px 0 80px 0;
    padding: 0 16px;
    overflow: hidden;
    /deep/ ul {
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
    /deep/ ul ul {
      list-style-type: circle;
      margin-block-start: 0px;
      margin-block-end: 0px;
    }
    /deep/ul ul ul {
      list-style-type: square;
    }
    /deep/ol {
      list-style: decimal inside;
      padding-left: 40px;
      margin: 16px 0;
    }
  }
  .notice-foot {
    width: 100%;
    height: 64px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-top: 1px solid #ebedf0;
    .notice-foot-left {
      margin-left: 16px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .notice-foot-right {
      margin-right: 16px;
      .nfr-button {
        width: 110px;
        height: 40px;
        background: #1d6fe9;
        border-radius: 4px;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #ffffff;
      }
    }
  }
}
</style>
